import React from 'react';
import './styles.scss';

class FeaturesGames extends React.Component {
    render() {
        return (
            <div className='triviaContainer'>
                <div className='featuresGamesEERWrap'>
                    <div className='featuresGamesEERLeft'>
                        <img
                            src={'https://sw-assets.gumlet.io/trivia/internal-branding/suite.svg?blur=30'}
                            data-src={'https://sw-assets.gumlet.io/trivia/internal-branding/suite.svg'}
                            alt={''}
                        />
                    </div>
                    <div className='featuresGamesEERRight'>
                        <h3>Trivia’s suite of games & features</h3>
                        <p>Try other Trivia games, all inside Slack & Microsoft Teams:</p>
                        <div className='fGBulletsPointsWrap'>
                            <div className='fGBulletsPoints'>
                                <p>Instant Quizzes</p>
                                <p>Gotcha!</p>
                            </div>
                            <div className='fGBulletsPoints'>
                                <p>(Un)Popular Opinions</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FeaturesGames;
