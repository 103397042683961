import React from 'react';
import Navbar from '../../../components/trivia/NavbarOld';
import Footer from '../../../components/trivia/Footer';
import GlobalClient from '../../../components/trivia/GlobalClient';
import { Helmet } from 'react-helmet';

import './styles.scss';
import Header from '../../../components/trivia/employer-employee-relationship/Header';
import Customizable from '../../../components/trivia/employer-employee-relationship/Customizable';
import FeaturesGames from '../../../components/trivia/employer-employee-relationship/FeaturesGames';
import WorkSpace from '../../../components/trivia/WorkSpace';

class EmployerEmployeeRelationship extends React.Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>Internal Branding and Employee Engagement | Trivia</title>
                    <meta
                        name='description'
                        content='How well do your employees know your organisation? With Trivia, align your team with your brand vision & develop stronger employee-employer relationships.'
                    />
                    <meta property='og:site_name' content='Springworks' />
                    <meta property='og:title' content='Internal Branding and Employee Engagement | Trivia' />
                    <meta
                        property='og:description'
                        content='How well do your employees know your organisation? With Trivia, align your team with your brand vision & develop stronger employee-employer relationships.'
                    />
                    <meta property='og:url' content='https://www.springworks.in/trivia/virtual-water-cooler/' />
                    <meta property='og:type' content='website' />
                    <meta
                        property='og:image'
                        content='https://assets-springworks.s3.amazonaws.com/VirtualWaterCooler.jpg'
                    />
                    <meta
                        property='og:image:secure_url'
                        content='https://assets-springworks.s3.amazonaws.com/VirtualWaterCooler.jpg'
                    />
                    <meta property='og:image:width' content='1280' />
                    <meta property='og:image:height' content='640' />
                    <meta property='twitter:title' content='Internal Branding and Employee Engagement | Trivia' />
                    <meta
                        property='twitter:description'
                        content='How well do your employees know your organisation? With Trivia, align your team with your brand vision & develop stronger employee-employer relationships.'
                    />
                    <meta property='twitter:card' content='website' />
                    <meta property='twitter:card' content='summary_large_image' />
                    <meta
                        property='twitter:image'
                        content='https://assets-springworks.s3.amazonaws.com/VirtualWaterCooler.jpg'
                    />
                    <meta property='twitter:site' content='@springroleinc' />
                    <link rel='canonical' href='https://www.springworks.in/trivia/internal-branding/' />
                </Helmet>
                <div className='EERWrap'>
                    <Navbar />
                    <Header />
                    <GlobalClient />
                    <Customizable />
                    <FeaturesGames />
                    <WorkSpace />
                    <Footer />
                </div>
            </>
        );
    }
}

export default EmployerEmployeeRelationship;
