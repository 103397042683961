import React from 'react';
import './styles.scss';

class Customizable extends React.Component {
    render() {
        return (
            <div className='customizableEERWrap'>
                <div className='triviaContainer'>
                    <div className='customizableEERHeader'>
                        <h2>
                            Customizable quiz templates to raise
                            <br />
                            internal brand awareness
                        </h2>
                    </div>
                    <div className='customizableEERLSectionWrap'>
                        <div className='customizableEERLSection'>
                            <div className='customizableEERLSectionLeft'>
                                <img
                                    src={'https://sw-assets.gumlet.io/trivia/internal-branding/connect.svg?blur=30'}
                                    data-src={'https://sw-assets.gumlet.io/trivia/internal-branding/connect.svg'}
                                    alt=''
                                />
                            </div>

                            <div className='customizableEERLSectionRight'>
                                <h4>Connect teams with your brand emotionally</h4>
                                <p>
                                    Help your colleagues make an emotional connection with your brand. It will inspire
                                    them to do more. Their energy will translate into happy customers.
                                </p>
                            </div>
                        </div>

                        <div className='customizableEERLSection'>
                            <div className='customizableEERLSectionLeft'>
                                <img
                                    src={'https://sw-assets.gumlet.io/trivia/internal-branding/personalize.svg?blur=30'}
                                    data-src={'https://sw-assets.gumlet.io/trivia/internal-branding/personalize.svg'}
                                    alt=''
                                />
                            </div>

                            <div className='customizableEERLSectionRight'>
                                <h4>Make learning about your brand fun</h4>
                                <p>
                                    Use our ready-made templates to launch real-time quizzes about your brand right
                                    inside your channels on Slack and Microsoft Teams.
                                </p>
                            </div>
                        </div>

                        <div className='customizableEERLSection'>
                            <div className='customizableEERLSectionLeft'>
                                <img
                                    src={'https://sw-assets.gumlet.io/trivia/internal-branding/learn.svg?blur=30'}
                                    data-src={'https://sw-assets.gumlet.io/trivia/internal-branding/learn.svg'}
                                    alt=''
                                />
                            </div>

                            <div className='customizableEERLSectionRight'>
                                <h4>Personalize your experience with Trivia</h4>
                                <p>
                                    Create quizzes from scratch. Craft your own questions, personalize your answers and
                                    launch them with Trivia.
                                </p>
                            </div>
                        </div>

                        <div className='customizableEERLSection'>
                            <div className='customizableEERLSectionLeft'>
                                <img
                                    src={'https://sw-assets.gumlet.io/trivia/internal-branding/celebrate.svg?blur=30'}
                                    data-src={'https://sw-assets.gumlet.io/trivia/internal-branding/celebrate.svg'}
                                    alt=''
                                />
                            </div>

                            <div className='customizableEERLSectionRight'>
                                <h4>Celebrate organization milestones</h4>
                                <p>
                                    Organize special events with your own themed quizzes to celebrate anniversaries,
                                    recognitions & special occasions.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Customizable;
