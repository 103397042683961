import React from 'react';
import './styles.scss';
import Zoom from 'react-reveal/Zoom';

const images = ['science.png', 'sugoilabs.png', 'quora.png', 'ea1-logo.svg', 'shopify.png', 'consenso-labs.png'];

const Customer = ({ image }) => (
    <span>
        <img
            src={`https://sw-assets.gumlet.io/trivia/customers/${image}?blur=30`}
            data-src={`https://sw-assets.gumlet.io/trivia/customers/${image}`}
            alt={image.split('-')[0]}
        />
    </span>
);

class GlobalClient extends React.Component {
    render() {
        return (
            <div className='GlobalClientWrap'>
                <div className='GlobalClient'>
                    <Zoom left cascade>
                        {images.map((image) => (
                            <Customer image={image} key={image} />
                        ))}
                    </Zoom>
                </div>
            </div>
        );
    }
}

export default GlobalClient;
